import AddIcon from '@material-ui/icons/AddCircleRounded';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { SubmitButton } from '../components/Buttons';
import { GenerateCsvPointages } from '../components/GenerateCsvPointages';
import Message from '../components/Message';
import { Context } from '../components/Store';
import {
  createPointage,
  deletePointage,
  getPointages,
  patchPointage,
} from '../functions/pointages';
import { getUser } from '../functions/user';
import { TIMEZONE_OFFSET } from '../utils/constants';

/**
 * Returns visualization / update page of pointages for a user
 * @param {*} props the object containing the string `match.params.userId` to get pointages using the user ID
 */
export default function PointagesByUser(props) {
  const [state] = useContext(Context);
  const history = useHistory();

  const [user, setUser] = useState({});
  const [pointages, setPointages] = useState([]);
  /* By default, setting minDate at 4am (local timezone), at the first day of the month */
  const [minDate, setMinDate] = useState(() => {
    const date = new Date();
    date.setHours(4 - TIMEZONE_OFFSET, 0, 0, 0);
    date.setDate(1);
    return date.toISOString().substring(0, 16);
  });
  /* By default, setting maxDate at the local date and time */
  const [maxDate, setMaxDate] = useState(() => {
    const date = new Date();
    date.setDate(
      new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
    );
    date.setHours(date.getHours() - TIMEZONE_OFFSET);
    return date.toISOString().substring(0, 16);
  });
  const [newPointage, setNewPointage] = useState('');

  const [error, setError] = useState('');
  const [info, setInfo] = useState('');
  const [warning, setWarning] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Fetch the user
   */
  const fetchUser = useCallback(async () => {
    setError('');
    setIsLoading(true);
    const fetchedUser = await getUser(props.match.params.userId, state.token);
    setIsLoading(false);
    if (fetchedUser.error) return setError(fetchedUser.error);
    return setUser(fetchedUser.data);
  }, [state.token, props.match.params.userId]);

  /**
   * Fetch pointages for a user and a time period
   */
  const fetchPointages = useCallback(async () => {
    setError('');
    if (minDate === '') return setError('Veuillez indiquer une date minimum');
    if (maxDate === '') return setError('Veuillez indiquer une date maximum');
    setIsLoading(true);
    // Fetching pointages between the dates
    const fetchedPointages = await getPointages(
      state.token,
      props.match.params.userId,
      new Date(minDate).toISOString(),
      new Date(maxDate).toISOString()
    );
    setIsLoading(false);
    if (fetchedPointages.error) return setError(fetchedPointages.error);
    // Formatting the data to return an object containing the dates as keys, and the pointages by date as values
    const formattedPointages = [];
    for (let i = 0; i < fetchedPointages.data.length; i++) {
      // Deleting the user field which is useless for this page
      delete fetchedPointages.data[i].user;
      const date = new Date(
        fetchedPointages.data[i].timestamp
      ).toLocaleDateString();
      if (formattedPointages[date]) {
        formattedPointages[date].pointages.push(fetchedPointages.data[i]);
      } else {
        formattedPointages[date] = { pointages: [fetchedPointages.data[i]] };
      }
    }
    // Setting the total for each day
    for (const date in formattedPointages) {
      const pointagesByDate = formattedPointages[date].pointages;
      let total = 0;
      for (let i = 0; i < pointagesByDate.length - 1; i++) {
        if (i % 2 === 0)
          total +=
            (new Date(pointagesByDate[i + 1].timestamp) -
              new Date(pointagesByDate[i].timestamp)) /
            (60 * 60 * 1000);
      }
      formattedPointages[date].total = Math.round(total * 100) / 100;
    }

    return setPointages(formattedPointages);
  }, [minDate, maxDate, state.token, props.match.params.userId]);

  /**
   * Calls the `patchPointage` function with the newDate value to update pointage, then fetches all pointages again
   * @param {String} pointageId The Id of the pointage to update
   * @param {Date} newDate the new Date object containing the new hour
   */
  const updatePointage = async (pointageId, newDate) => {
    setError('');
    setIsLoading(true);
    const updated = await patchPointage(
      state.token,
      pointageId,
      newDate.toISOString()
    );
    setIsLoading(false);
    if (updated.error) return setError(updated.error);
    setPointages({});
    fetchPointages();
  };

  /**
   * Calls the createPointage function with the newPointage value, then fetches pointages again
   */
  const addPointage = async () => {
    if (newPointage === '')
      return setWarning(
        'Veuillez sélectionner une date et une heure à ajouter.'
      );
    setError('');
    setWarning('');
    setIsLoading(true);
    const date = new Date(newPointage);
    const postNewPointage = await createPointage(
      state.token,
      props.match.params.userId,
      date.toISOString()
    );
    setIsLoading(false);
    if (postNewPointage.error) return setError(postNewPointage.error);
    setPointages({});
    fetchPointages();
  };

  /**
   * Calls the deletePointage function, then fetches pointages again
   * @param {String} id The id of the pointage to delete
   */
  const removePointage = async (id) => {
    if (window.confirm("La suppression d'un pointage est définitive.")) {
      setError('');
      setIsLoading(true);
      const deleted = await deletePointage(state.token, id);
      if (deleted.error) return setError(deleted.error);
      setPointages({});
      fetchPointages();
    }
  };

  // Fetches user and pointages at page loading
  useEffect(() => {
    fetchUser();
    fetchPointages();
  }, [fetchUser, fetchPointages]);

  document.title = `${
    user.prenom && user.nom
      ? `Pointages de ${user.prenom} ${user.nom}`
      : 'Pointages'
  }`;

  return (
    <div className="flex flex-col items-center">
      <div className="w-full lg:w-11/12">
        <h1 className="mt-6 text-2xl sm:text-3xl md:text-4xl uppercase font-bold mb-8">
          {`${
            user.prenom && user.nom
              ? `Pointages de ${user.prenom} ${user.nom}`
              : 'Pointages'
          }`}
        </h1>
        {error ? <Message message={error} setError={setError} /> : null}
        {warning ? (
          <Message message={warning} type="warning" setError={setWarning} />
        ) : null}
        {info ? (
          <Message message={info} type="success" setError={setInfo} />
        ) : null}

        <div className="bg-viguierBleu rounded">
          <div className="flex items-center justify-between py-2 font-bold bg-viguierBleuClair text-white rounded-t">
            <div className="flex mx-3">
              <button
                title="Actualiser les pointages"
                onClick={fetchPointages}
                className="rounded hover:text-viguierJaune"
              >
                <RefreshIcon />
              </button>
            </div>
            <div className="flex flex-wrap justify-end md:justify-center items-center">
              <div className="flex flex-wrap justify-end items-center">
                <span className="ml-auto mr-auto sm:mr-2">Entre le</span>
                <input
                  type="datetime-local"
                  value={minDate}
                  title="Saisissez ici la date minimale"
                  onChange={(e) => setMinDate(e.target.value)}
                  className="rounded pl-2 py-1 my-1 mr-2 focus:outline-none focus:ring-2 focus:ring-stbSecond focus:border-gray-800 text-black"
                />
              </div>
              <div className="flex flex-wrap justify-end items-center">
                <span className="ml-auto mr-auto sm:mr-2">et le</span>
                <input
                  type="datetime-local"
                  value={maxDate}
                  title="Saisissez ici la date maximale"
                  onChange={(e) => setMaxDate(e.target.value)}
                  className="rounded pl-2 py-1 my-1 mr-2 focus:outline-none focus:ring-2 focus:ring-stbSecond focus:border-gray-800 text-black"
                />
              </div>
            </div>
          </div>
          {isLoading ? (
            <Message message="Chargement..." type="success" />
          ) : (
            <div className="px-2 w-11/12 border-4 border-white mb-5 mt-5 ml-3 sm:ml-12 bg-viguierGrisClair rounded-md">
              {Object.keys(pointages).map((date, i) => {
                const pointagesByDate = pointages[date];
                return (
                  <div
                    key={i}
                    className={`flex my-1 py-2 items-center ${
                      i !== Object.keys(pointages).length - 1
                        ? 'border-b-4  '
                        : ''
                    }`}
                  >
                    <p className="font-bold mr-4 lg:w-1/6">{date}</p>
                    <div className="flex w-full items-center justify-betweens overflow-x-auto">
                      {pointagesByDate.pointages.map((pointage, i) => (
                        <TimeInput
                          key={i}
                          defaultTimestamp={pointage.timestamp}
                          id={pointage._id}
                          col={i}
                          updateTime={updatePointage}
                          removeTime={removePointage}
                          setWarning={setWarning}
                        />
                      ))}
                    </div>
                    <div
                      title={
                        pointagesByDate.pointages.length % 2 !== 0
                          ? "Cette journée n'est pas terminée"
                          : ''
                      }
                      className={`mx-4 px-1 min-w-max rounded ${
                        pointagesByDate.pointages.length % 2 === 0
                          ? 'bg-stbMain'
                          : 'bg-red-400 animate-pulse cursor-help'
                      } text-white`}
                    >
                      Total : {pointagesByDate.total}
                    </div>
                  </div>
                );
              })}
              {Object.keys(pointages).length === 0 && (
                <Message
                  message={
                    "Aucune données de pointage n'est disponible pour cette période"
                  }
                  type="warning"
                />
              )}
            </div>
          )}
          {Object.keys(pointages).length !== 0 && (
            <div className="flex flex-wrap justify-center items-center bg-viguierBleu text-white">
              <div className="flex w-1/2 min-w-max">
                <Message
                  message={`Nombre d'heures pour cette période : ${
                    Math.round(
                      Object.keys(pointages)
                        .map((d) => pointages[d].total)
                        .reduce((a, b) => a + b, 0) * 100
                    ) / 100
                  }`}
                  type="success"
                />
              </div>
              <div className="flex justify-center w-1/2 min-w-max my-2">
                <GenerateCsvPointages
                  pointages={pointages}
                  user={user}
                  dateMin={minDate}
                  dateMax={maxDate}
                />
              </div>
            </div>
          )}
          <div className="flex flex-wrap justify-between items-center px-2 rounded-b bg-viguierBleuClair text-white">
            <div className="my-2">
              <SubmitButton
                action={() => history.goBack()}
                label="Revenir aux pointages du jour"
              />
            </div>
            <div className="flex flex-wrap justify-center items-center">
              <button
                className="bg-viguierBleu flex flex-wrap mx-1 rounded cursor-pointer hover:bg-viguierBleuFoncé hover:text-viguierJaune"
                onClick={addPointage}
                title="Ajouter le pointage correspondant à la date et l'heure saisie"
              >
                <AddIcon className="mx-1 rounded-full" />
                <p className="mx-1">Ajouter un pointage</p>
              </button>
              <input
                type="datetime-local"
                value={newPointage}
                title="Saisissez ici la date et l'heure du pointage à ajouter"
                onChange={(e) => setNewPointage(e.target.value)}
                className="my-1 min-w-min text-center rounded focus:outline-none focus:ring-2 focus:ring-stbSecond focus:border-gray-800 text-black"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

/**
 * Returns a time input with its own state, and displays either a check icon to validate an update on the time, or a delete icon to remove this time
 * @param {*} props Containing default time, the id of the pointage, the column number, the update function and the remove function
 * @returns
 */
function TimeInput({
  defaultTimestamp,
  id,
  col,
  updateTime,
  removeTime,
  setWarning,
}) {
  const defaultTime = new Date(defaultTimestamp)
    .toLocaleTimeString()
    .substring(0, 5);
  const [time, setTime] = useState(defaultTime);

  return (
    <div className="mr-2 lg:mr-10 flex items-center group rounded-lg border border-opacity-0 hover:border-opacity-100">
      {/* If the pointage is a start time, displayed in green. If it is an end time, displayed in red */}
      <input
        type="time"
        title={`Modifier l'horaire du pointage ${
          col % 2 === 0 ? 'entrant' : 'sortant'
        }`}
        className={`px-1 min-w-min text-center border-2 border-stbSecond rounded-lg focus:outline-none focus:ring-2 focus:ring-stbSecond focus:border-gray-800 text-black ${
          col % 2 === 0 ? 'bg-green-400' : 'bg-red-400'
        }`}
        onChange={(e) => setTime(e.target.value)}
        value={time}
      />
      {/* If the time is modified, the check icon is displayed. Else, the delete icon is displayed */}
      {defaultTime !== time ? (
        <button
          className="cursor-pointer"
          onClick={() => {
            /** The hour is formatted in a new Date object, with the same date in the defaultTimestamp param */
            const newTimestamp = new Date(defaultTimestamp);
            const hours = time.split(':')[0];
            const minutes = time.split(':')[1];
            if (hours === '' || minutes === '')
              return setWarning("La date saisie n'est pas valide");
            newTimestamp.setHours(hours, minutes);
            updateTime(id, newTimestamp, col);
          }}
          title="Valider la modification"
        >
          <CheckIcon />
        </button>
      ) : (
        <button
          className="cursor-pointer opacity-0 group-hover:opacity-100"
          onClick={() => removeTime(id)}
          title="Supprimer cet horaire"
        >
          <DeleteIcon />
        </button>
      )}
    </div>
  );
}
