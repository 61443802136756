export const API_URL = process.env.REACT_APP_API_URL;
export const regexUsername = /^[a-zA-Z0-9]+([_-]?[a-zA-Z0-9]){4,}$/;
export const regexPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;

export const TIMEZONE_OFFSET = new Date().getTimezoneOffset() / 60;

const mapSelect = (array) => {
  return array.map((type, i) => {
    return { value: i + 1, label: type };
  });
};

export const subTypeFicheValues = [
  { value: 6, label: 'Triphasé' },
  { value: 1, label: 'Pompe' },
  { value: 2, label: 'Monophasé' },
  { value: 7, label: 'Motopompe' },
  { value: 8, label: 'Réducteur' },
  { value: 9, label: 'Motoréducteur' },
  { value: 5, label: 'Courant Continu' },
  { value: 3, label: 'Alternateur' },
  // { value: 4, label: 'Rotor Bobiné' },
];

const ficheStatusValues = ['Non commencé', 'En cours', 'Terminé', 'Archivé'];
const typeValues = ['Chantier', 'Démontage', 'Remontage', 'Bobinage'];
const userStatusValues = ['Technicien', "Chef d'atelier", 'Administrateur'];
const aspectValues = mapSelect(['Propre', 'Sale', 'Très sale']);
const etatMecaValues = mapSelect(['OK', 'A contrôler', 'A rebaguer']);
const etatMeca2Values = mapSelect(['Bon état', 'Cassé', 'Absent']);
const typeRoulementValues = ['2Z/ECJ', '2RS/ECP', 'C3', 'M'].map((val) => {
  return { value: val, label: val };
});

export const mapStatus = (statusCode) => {
  return ficheStatusValues[statusCode - 1] || 'Statut inconnu';
};

export const mapType = (typeCode) => {
  return typeValues[typeCode - 1] || 'Type inconnu';
};

export const mapSubtype = (subtypeCode) => {
  return subTypeFicheValues.find((s) => s.value === parseInt(subtypeCode))
    .label;
};

export const mapUserStatus = (statusCode) => {
  return userStatusValues[statusCode - 1] || 'Statut inconnu';
};

export const typeFicheList = mapSelect(typeValues);
export const statusFicheList = mapSelect(ficheStatusValues);

const fiche = {
  numDevis: { label: 'Numéro de devis', required: true },
  numFiche: { label: "Numéro d'intervention", required: true },
  type: {
    label: 'Type de fiche',
    required: true,
    type: 'select',
    values: mapSelect(typeValues),
  },
  author: { label: 'Créateur de la fiche', readonly: true },
  status: {
    label: 'Statut',
    required: true,
    type: 'select',
    values: mapSelect(ficheStatusValues),
  },
  client: { label: 'Client', required: true, type: 'select', data: 'clients' },
  resp: { label: 'Technicien responsable', type: 'select', data: 'users' },
  contact: { label: 'Nom du contact' },
  telContact: { label: 'Numéro de téléphone du contact', type: 'tel' },
  dateDebut: { label: 'Date de début', type: 'date' },
  dureeTotale: {
    label: "Durée totale de l'intervention",
    type: 'time',
    unit: 'heures:minutes:secondes',
  },
  observations: { label: 'Observations', type: 'textarea' },
  photos: { label: 'Photos', type: 'array', subtype: 'image' },
};

export const ficheChantier = {
  techniciens: {
    label: 'Techniciens présents',
    type: 'select',
    data: 'users',
    multiple: true,
  },
  adresseChantier: { label: 'Adresse du chantier' },
  vehicule: { label: 'Véhicule', type: 'select', data: 'vehicles' },
  objet: { label: 'Objet du chantier', type: 'textarea' },
  materiel: { label: 'Matériel nécessaire', type: 'textarea' },
  diagnostic: { label: 'Diagnostic', type: 'textarea' },
  signatureTech: { label: 'Signature du technicien', type: 'image' },
  signatureClient: { label: 'Signature du client', type: 'image' },
  dureeEssai: { label: "Durée de l'essai" },
};

export const ficheDemontage = {
  subtype: {
    label: 'Type de fiche Démontage',
    required: true,
    type: 'select',
    values: subTypeFicheValues,
  },
  marque: { label: 'Marque' },
  typeMoteur: { label: 'Type de moteur' },
  numSerie: { label: 'Numéro de série' },
  arbreSortantEntrant: {
    label: 'Arbre',
    type: 'radio',
    options: [
      { label: 'Entrant', value: true },
      { label: 'Sortant', value: false },
    ],
  },
  accouplement: { label: 'Accouplement', type: 'radio' },
  coteAccouplement: { label: 'Cote accouplement' },
  clavette: { label: 'Clavette', type: 'radio' },
  aspect: {
    label: 'Aspect',
    type: 'select',
    values: aspectValues,
  },
  aspectInterieur: {
    label: 'Aspect intérieur',
    type: 'select',
    values: aspectValues,
  },
  flasqueAvant: {
    label: 'Flasque Avant',
    type: 'select',
    values: etatMecaValues,
  },
  flasqueArriere: {
    label: 'Flasque Arrière',
    type: 'select',
    values: etatMecaValues,
  },
  porteeRAvant: {
    label: 'Portée roulement avant',
    type: 'select',
    values: etatMecaValues,
  },
  porteeRArriere: {
    label: 'Portée roulement arrière',
    type: 'select',
    values: etatMecaValues,
  },
  boutArbre: {
    label: "Bout d'arbre",
    type: 'radio',
    options: [
      { label: 'OK', value: true },
      { label: 'Mauvais', value: false },
    ],
  },
  refRoulementAvant: {
    label: 'Roulement avant - Référence',
    type: 'array',
    subtype: 'text',
  },
  refRoulementArriere: {
    label: 'Roulement arrière - Référence',
    type: 'array',
    subtype: 'text',
  },
  typeRoulementAvant: {
    label: 'Type de roulement avant',
    type: 'array',
    subtype: 'select',
    values: typeRoulementValues,
  },
  typeRoulementArriere: {
    label: 'Type de roulement arrière',
    type: 'array',
    subtype: 'select',
    values: typeRoulementValues,
  },
  refJointAvant: { label: 'Joint avant - Référence' },
  refJointArriere: { label: 'Joint arrière - Référence' },
  typeJointAvant: {
    label: 'Type de joint avant',
    type: 'radio',
    options: [
      { label: 'Simple lèvre', value: false },
      { label: 'Double lèvre', value: true },
    ],
  },
  typeJointArriere: {
    label: 'Type de joint arrière',
    type: 'radio',
    options: [
      { label: 'Simple lèvre', value: false },
      { label: 'Double lèvre', value: true },
    ],
  },
  etuvage: { label: 'Etuvage', type: 'radio' },
  bobinage: { label: 'Bobinage', type: 'radio' },
  frein: { label: 'Frein', type: 'radio' },
  tensionFrein: { label: 'Tension frein' },
};

export const ficheDemontageExceptPompeReducteur = {
  puissance: { label: 'Puissance', unit: 'kW' },
  bride: { label: 'Bride' },
  vitesse: { label: 'Vitesse' },
  couplage: { label: 'Couplage' },
  rondelleElastique: {
    label: 'Rondelle élastique',
    type: 'radio',
    options: [
      { label: 'Avant', value: true },
      { label: 'Arrière', value: false },
    ],
  },
  ventilateur: {
    label: 'Ventilateur',
    type: 'select',
    values: mapSelect(['Bon état', 'A changer', 'Absent']),
  },
  capotV: {
    label: 'Capot de ventilateur',
    type: 'select',
    values: etatMeca2Values,
  },
  socleBoiteABorne: {
    label: 'Boite à borne - Socle',
    type: 'select',
    values: etatMeca2Values,
  },
  capotBoiteABorne: {
    label: 'Boite à borne - Capot',
    type: 'select',
    values: etatMeca2Values,
  },
  plaqueABorne: {
    label: 'Plaque à borne',
    type: 'select',
    values: mapSelect(['Bon état', 'A changer', 'Sortie par câbles']),
  },
  presenceSondes: { label: 'Présence sondes', type: 'radio' },
  typeSondes: { label: 'Type de sondes' },
  equilibrage: { label: 'Equilibrage', type: 'radio' },
  peinture: { label: 'Peinture' },
  tensionU: { label: 'Tension U', unit: 'V' },
  tensionV: { label: 'Tension V', unit: 'V' },
  tensionW: { label: 'Tension W', unit: 'V' },
};

export const ficheDemontageExceptPompeReducteurMonophase = {
  isolementPhase: { label: 'Isolement Phase', unit: 'MΩ' },
};

export const ficheDemontagePompe = {
  fluide: { label: 'Fluide à étancher' },
  sensRotation: {
    label: 'Sens de rotation',
    type: 'radio',
    options: [
      { label: 'Gauche', value: false },
      { label: 'Droite', value: true },
    ],
  },
  typeRessort: {
    label: 'Type mobile',
    type: 'select',
    values: mapSelect([
      'Entraînement par vis',
      'Ressort axial conique',
      'Ressort axial cylindrique',
      'Soufflet',
    ]),
  },
  typeJoint: { label: 'Type de joint (Garniture 1)' },
  matiere: {
    label: 'Matière (Garniture 1)',
    type: 'select',
    values: mapSelect([
      'Céramique',
      'Carbone Silicium',
      'Carbone',
      'Tungstène',
    ]),
  },
  diametreArbre: { label: "Diamètre de l'arbre (Garniture 1)" },
  diametreExtPF: {
    label: 'Diamètre extérieur de la partie fixe (Garniture 1)',
  },
  epaisseurPF: { label: 'Epaisseur de la partie fixe (Garniture 1)' },
  diametreExtPR: {
    label: 'Diamètre extérieur de la partie rotative (Garniture 1)',
  },
  longueurRotativeNonComprimee: {
    label: 'Longueur de la partie rotative non comprimée (Garniture 1)',
  },
  longueurRotativeComprimee: {
    label: 'Longueur de la partie rotative comprimée à fond (Garniture 1)',
  },
  longueurRotativeTravail: {
    label:
      'Longueur de la partie rotative en position de travail (Garniture 1)',
  },
  typeJoint2: { label: 'Type de joint (Garniture 2)' },
  matiere2: {
    label: 'Matière (Garniture 2)',
    type: 'select',
    values: mapSelect([
      'Céramique',
      'Carbone Silicium',
      'Carbone',
      'Tungstène',
    ]),
  },
  diametreArbre2: { label: "Diamètre de l'arbre (Garniture 2)" },
  diametreExtPF2: {
    label: 'Diamètre extérieur de la partie fixe (Garniture 2)',
  },
  epaisseurPF2: { label: 'Epaisseur de la partie fixe (Garniture 2)' },
  diametreExtPR2: {
    label: 'Diamètre extérieur de la partie rotative (Garniture 2)',
  },
  longueurRotativeNonComprimee2: {
    label: 'Longueur de la partie rotative non comprimée (Garniture 2)',
  },
  longueurRotativeComprimee2: {
    label: 'Longueur de la partie rotative comprimée à fond (Garniture 2)',
  },
  longueurRotativeTravail2: {
    label:
      'Longueur de la partie rotative en position de travail (Garniture 2)',
  },
};

export const ficheDemontageMonophase = {
  resistanceTravail: { label: 'Résistance travail', unit: 'Ω' },
  resistanceDemarrage: { label: 'Résistance démarrage', unit: 'Ω' },
  valeurCondensateur: { label: 'Valeur du condensateur' },
  diametreArbre: { label: 'Diamètre arbre', unit: 'mm' },
  hauteurAxe: { label: "Hauteur d'axe" },
  coteInterieureArbre: { label: 'Cotes interieur arbre', unit: 'cm' },
  coteExterieureArbre: { label: 'Cotes extérieur arbre', unit: 'cm' },
  entreTrous: { label: 'Entre-trous', unit: 'cm' },
};

export const ficheDemontageAlternateur = {
  isolementMasseStatorPrincipalU: {
    label: 'Isolement masse stator principal U',
    unit: 'MΩ',
  },
  isolementMasseStatorPrincipalV: {
    label: 'Isolement masse stator principal V',
    unit: 'MΩ',
  },
  isolementMasseStatorPrincipalW: {
    label: 'Isolement masse stator principal W',
    unit: 'MΩ',
  },
  isolementMasseRotorPrincipal: {
    label: 'Isolement masse rotor principal',
    unit: 'MΩ',
  },
  isolementMasseStatorExcitation: {
    label: 'Isolement masse stator excitation',
    unit: 'MΩ',
  },
  isolementMasseRotorExcitation: {
    label: 'Isolement masse rotor excitation',
    unit: 'MΩ',
  },
  resistanceRotorPrincipal: { label: 'Résistance rotor principal', unit: 'Ω' },
  resistanceStatorExcitation: {
    label: 'Résistance stator excitation',
    unit: 'Ω',
  },
  resistanceRotorExcitation: {
    label: 'Résistance rotor excitation',
    unit: 'Ω',
  },
  testDiode: {
    label: 'Test diode',
    type: 'radio',
    options: [
      { label: 'Fonctionne', value: true },
      { label: 'Ne fonctionne pas', value: false },
    ],
  },
};

export const ficheDemontageCourantContinu = {
  isolementMasseInduit: { label: 'Isolement masse induit', unit: 'MΩ' },
  isolementMassePolesPrincipaux: {
    label: 'Isolement masse - Pôles principaux',
    unit: 'MΩ',
  },
  isolementMassePolesAuxilliaires: {
    label: 'Isolement masse - Pôles auxilliaires',
    unit: 'MΩ',
  },
  isolementMassePolesCompensatoires: {
    label: 'Isolement masse - Pôles compensatoires',
    unit: 'MΩ',
  },
  isolementMassePorteBalais: {
    label: 'Isolement masse - Porte balais',
    unit: 'MΩ',
  },
  resistanceInduit: { label: 'Résistance induit', unit: 'Ω' },
  resistancePP: { label: 'Résistance PP', unit: 'Ω' },
  resistancePA: { label: 'Résistance PA', unit: 'Ω' },
  resistancePC: { label: 'Résistance PC', unit: 'Ω' },
};

export const ficheDemontageTriphase = {
  isolementPhaseMasseStatorUM: {
    label: 'Isolement phase/masse stator UM',
    unit: 'MΩ',
  },
  isolementPhaseMasseStatorVM: {
    label: 'Isolement phase/masse stator VM',
    unit: 'MΩ',
  },
  isolementPhaseMasseStatorWM: {
    label: 'Isolement phase/masse stator WM',
    unit: 'MΩ',
  },
  isolementPhasePhaseStatorUV: {
    label: 'Isolement phase/phase stator UV',
    unit: 'MΩ',
  },
  isolementPhasePhaseStatorVW: {
    label: 'Isolement phase/phase stator VW',
    unit: 'MΩ',
  },
  isolementPhasePhaseStatorUW: {
    label: 'Isolement phase/phase stator UW',
    unit: 'MΩ',
  },
  resistanceStatorU: { label: 'Résistance stator U', unit: 'Ω' },
  resistanceStatorV: { label: 'Résistance stator V', unit: 'Ω' },
  resistanceStatorW: { label: 'Résistance stator W', unit: 'Ω' },
  diametreArbre: { label: 'Diamètre arbre', unit: 'mm' },
  hauteurAxe: { label: "Hauteur d'axe" },
  coteInterieureArbre: { label: 'Cotes interieur arbre', unit: 'cm' },
  coteExterieureArbre: { label: 'Cotes extérieur arbre', unit: 'cm' },
  entreTrous: { label: 'Entre-trous', unit: 'cm' },
};

export const ficheDemontageMotopompe = {
  typeMotopompe: {
    label: 'Type de motopompe',
    type: 'select',
    values: mapSelect(['Triphasé', 'Monophasé']),
  },
};

export const ficheDemontageReducteur = {
  trMinute: { label: 'Tours par minute', unit: 'tr/min' },
  modele: { label: 'Modèle' },
  indiceReduction: { label: 'Indice de réduction' },
  typeHuile: { label: "Type d'huile" },
  quantiteHuile: { label: "Quantité d'huile" },
  roulements: {
    label: 'Roulements',
    type: 'array',
    subtype: 'object',
    values: {
      title: { label: 'Arbre' },
      roulementAvant: { label: 'Roulement avant' },
      roulementArriere: { label: 'Roulement arrière' },
    },
  },
  joints: {
    label: 'Joints',
    type: 'array',
    subtype: 'object',
    values: {
      title: { label: 'Arbre' },
      jointAvant: { label: 'Joint avant' },
      jointArriere: { label: 'Joint arrière' },
    },
  },
};

export const ficheDemontageMotoreducteur = {
  typeMotoreducteur: {
    label: 'Type de Motoréducteur',
    type: 'select',
    values: mapSelect(['Triphasé', 'Monophasé']),
  },
};

export const ficheRemontage = {
  subtype: {
    label: 'Type de fiche Remontage',
    required: true,
    type: 'select',
    values: subTypeFicheValues,
  },
  dureeEssai: { label: "Durée de l'essai", type: 'number' },
  remontageRoulement: {
    label: 'Remontage roulement',
    type: 'select',
    values: mapSelect([
      'A la presse',
      'Douille de frappe',
      'Chauffe roulement',
    ]),
  },
  collageRoulementPorteeArbre: {
    label: "Collage du roulement sur la portée d'arbre",
    type: 'select',
    values: mapSelect(['Avant', 'Arrière', 'Aucun']),
  },
  collageRoulementFlasque: {
    label: "Collage du roulement à l'intérieur de la flasque",
    type: 'select',
    values: mapSelect(['Avant', 'Arrière', 'Aucun']),
  },
};

export const ficheRemontageExceptPompeReducteur = {
  isolementPhaseMasseU: { label: 'Isolement phase/masse U' },
  isolementPhaseMasseV: { label: 'Isolement phase/masse V' },
  isolementPhaseMasseW: { label: 'Isolement phase/masse W' },
  isolementPhasePhaseU: { label: 'Isolement phase/phase U' },
  isolementPhasePhaseV: { label: 'Isolement phase/phase V' },
  isolementPhasePhaseW: { label: 'Isolement phase/phase W' },
  resistanceStatorU: { label: 'Résistance stator U', unit: 'Ω' },
  resistanceStatorV: { label: 'Résistance stator V', unit: 'Ω' },
  resistanceStatorW: { label: 'Résistance stator W', unit: 'Ω' },
  // tension: { label: 'Tension', unit: 'V' },
  intensiteU: { label: 'Courant U', unit: 'A' },
  intensiteV: { label: 'Courant V', unit: 'A' },
  intensiteW: { label: 'Courant W', unit: 'A' },
};

export const ficheRemontageExceptPompeReducteurMonophase = {
  isolementPhase: { label: 'Isolement phase' },
};

export const ficheRemontagePompe = {};

export const ficheRemontageMonophase = {
  resistanceTravail: { label: 'Résistance travail', unit: 'Ω' },
  resistanceDemarrage: { label: 'Résistance démarrage', unit: 'Ω' },
};

export const ficheRemontageAlternateur = {
  isolementMasseStatorPrincipalU: {
    label: 'Isolement masse stator principal U',
    unit: 'MΩ',
  },
  isolementMasseStatorPrincipalV: {
    label: 'Isolement masse stator principal V',
    unit: 'MΩ',
  },
  isolementMasseStatorPrincipalW: {
    label: 'Isolement masse stator principal W',
    unit: 'MΩ',
  },
  isolementMasseRotorPrincipal: {
    label: 'Isolement masse rotor principal',
    unit: 'MΩ',
  },
  isolementMasseStatorExcitation: {
    label: 'Isolement masse stator excitation',
    unit: 'MΩ',
  },
  isolementMasseRotorExcitation: {
    label: 'Isolement masse rotor excitation',
    unit: 'MΩ',
  },
  resistanceRotorPrincipal: { label: 'Résistance rotor principal', unit: 'Ω' },
  resistanceStatorExcitation: {
    label: 'Résistance stator excitation',
    unit: 'Ω',
  },
  resistanceRotorExcitation: {
    label: 'Résistance rotor excitation',
    unit: 'Ω',
  },
  tensionExcitation: { label: 'Tension excitation', unit: 'V' },
  intensiteExcitation: { label: 'Intensité excitation', unit: 'A' },
};

export const ficheRemontageCourantContinu = {
  verificationFixationCouronne: {
    label: 'Vérification fixation couronne',
    type: 'radio',
  },
  isolementPorteBalais: { label: 'Isolement porte balais', unit: 'V' },
  isolementPhaseMasseRotorU: {
    label: 'Isolement phase/masse rotor U',
    unit: 'MΩ',
  },
  isolementPhaseMasseRotorV: {
    label: 'Isolement phase/masse rotor V',
    unit: 'MΩ',
  },
  isolementPhaseMasseRotorW: {
    label: 'Isolement phase/masse rotor W',
    unit: 'MΩ',
  },
  isolementPhaseRotorUV: { label: 'Isolement phase/rotor UV', unit: 'MΩ' },
  isolementPhaseRotorVW: { label: 'Isolement phase/rotor VW', unit: 'MΩ' },
  isolementPhaseRotorUW: { label: 'Isolement phase/rotor UW', unit: 'MΩ' },
  isolementInduit: { label: 'Isolement induit', unit: 'MΩ' },
  isolementPolePrincipal: { label: 'Isolement pôle principal', unit: 'MΩ' },
  isolementPoleAuxilliaire: { label: 'Isolement pôle auxilliaire', unit: 'MΩ' },
  isolementPoleCompensatoire: {
    label: 'Isolement pôle compensatoire',
    unit: 'MΩ',
  },
  resistanceInduit: { label: 'Résistance induit', unit: 'Ω' },
  resistancePolePrincipal: { label: 'Résistance pôle principal', unit: 'Ω' },
  resistancePoleAuxilliaire: {
    label: 'Résistance pôle auxilliaire',
    unit: 'Ω',
  },
  resistancePoleCompensatoire: {
    label: 'Résistance pôle compensatoire',
    unit: 'Ω',
  },
  tensionInducteursU: { label: 'Tension inducteurs U', unit: 'V' },
  tensionInducteursV: { label: 'Tension inducteurs V', unit: 'V' },
  tensionInducteursW: { label: 'Tension inducteurs W', unit: 'V' },
  intensiteInducteursU: { label: 'Intensité inducteurs U', unit: 'A' },
  intensiteInducteursV: { label: 'Intensité inducteurs V', unit: 'A' },
  intensiteInducteursW: { label: 'Intensité inducteurs W', unit: 'A' },
  tensionInduitU: { label: 'Tension induit', unit: 'V' },
  tensionInduitV: { label: 'Tension induit', unit: 'V' },
  tensionInduitW: { label: 'Tension induit', unit: 'V' },
  intensiteInduit: { label: 'Intensité induit', unit: 'A' },
  tensionRotorU: { label: 'Tension Rotor U', unit: 'V' },
  tensionRotorV: { label: 'Tension Rotor V', unit: 'V' },
  tensionRotorW: { label: 'Tension Rotor W', unit: 'V' },
  tensionExcitation: { label: 'Tension excitation', unit: 'V' },
  intensiteExcitation: { label: 'Intensité excitation', unit: 'A' },
};

export const ficheRemontageMotopompe = {
  typeMotopompe: {
    label: 'Type de motopompe',
    type: 'select',
    values: mapSelect(['Triphasé', 'Monophasé']),
  },
};

export const ficheRemontageReducteur = {};

export const ficheRemontageMotoReducteur = {
  typeMotoreducteur: {
    label: 'Type de Motoréducteur',
    type: 'select',
    values: mapSelect(['Triphasé', 'Monophasé']),
  },
};

export const ficheBobinage = {
  marqueMoteur: { label: 'Marque du moteur' },
  typeBobinage: { label: 'Type de bobinage' },
  puissance: { label: 'Puissance', type: 'number', unit: 'W' },
  vitesse: { label: 'Vitesse', type: 'number', unit: 'tr/min' },
  tension: { label: 'Tension', unit: 'V' },
  phases: { label: 'Phases', type: 'number' },
  frequences: { label: 'Fréquences', type: 'number', unit: 'Hz' },
  courant: { label: 'Courant', type: 'number', unit: 'A' },
  calageEncoches: { label: 'Calage encoches', type: 'radio' },
  nbEncoches: { label: "Nombre d'encoches", type: 'number' },
  nbSpires: { label: 'Nombre de spires', type: 'number' },
  presenceSondes: { label: 'Présence sondes', type: 'radio' },
  typeSondes: { label: 'Type de sondes' },
  pasPolaire: { label: 'Pas polaire' },
  branchement: { label: 'Couplage moteur' },
  resistanceU: { label: 'Résistance U', type: 'number', unit: 'Ω' },
  resistanceV: { label: 'Résistance V', type: 'number', unit: 'Ω' },
  resistanceW: { label: 'Résistance W', type: 'number', unit: 'Ω' },
  isolementUT: { label: 'Isolement U/t', unit: 'MΩ' },
  isolementVT: { label: 'Isolement V/t', unit: 'MΩ' },
  isolementWT: { label: 'Isolement W/t', unit: 'MΩ' },
  isolementUV: { label: 'Isolement U/V', unit: 'MΩ' },
  isolementUW: { label: 'Isolement U/W', unit: 'MΩ' },
  isolementVW: { label: 'Isolement V/W', unit: 'MΩ' },
  poids: { label: 'Poids du cuivre utilisé', type: 'number', unit: 'Kg' },
  sectionsFils: {
    label: 'Sections de fils',
    type: 'array',
    subtype: 'object',
    values: {
      diametre: { label: 'Diamètre', type: 'number', unit: 'mm' },
      nbBrins: { label: 'Nombre de brins', type: 'number' },
    },
  },
};

export const ficheFields = {
  0: {
    title: 'Informations générales',
    fields: {
      ...fiche,
    },
  },
  1: {
    title: 'Fiche Chantier',
    fields: {
      ...ficheChantier,
    },
  },
  2: {
    title: 'Fiche démontage - Informations générales',
    fields: {
      ...ficheDemontage,
    },
  },
  3: {
    title: 'Fiche Remontage - Informations générales',
    fields: {
      ...ficheRemontage,
    },
  },
  4: {
    title: 'Fiche Bobinage',
    fields: {
      ...ficheBobinage,
    },
  },
  21: {
    title: 'Fiche Démontage - Pompe',
    fields: {
      ...ficheDemontagePompe,
    },
  },
  22: {
    title: 'Fiche Démontage - Moteur Monophasé',
    fields: {
      ...ficheDemontageExceptPompeReducteur,
      ...ficheDemontageMonophase,
    },
  },
  23: {
    title: 'Fiche Démontage - Moteur Alternateur',
    fields: {
      ...ficheDemontageExceptPompeReducteur,
      ...ficheDemontageExceptPompeReducteurMonophase,
      ...ficheDemontageAlternateur,
    },
  },
  25: {
    title: 'Fiche Démontage - Moteur Courant Continu',
    fields: {
      ...ficheDemontageExceptPompeReducteur,
      ...ficheDemontageExceptPompeReducteurMonophase,
      ...ficheDemontageCourantContinu,
    },
  },
  26: {
    title: 'Fiche Démontage - Moteur Triphasé',
    fields: {
      ...ficheDemontageExceptPompeReducteur,
      ...ficheDemontageExceptPompeReducteurMonophase,
      ...ficheDemontageTriphase,
    },
  },
  27: {
    title: 'Fiche Démontage - Motopompe',
    fields: {
      ...ficheDemontageMotopompe,
      ...ficheDemontagePompe,
      ...ficheDemontageExceptPompeReducteur,
      ...ficheDemontageExceptPompeReducteurMonophase,
      ...ficheDemontageTriphase,
      ...ficheDemontageMonophase,
    },
  },
  28: {
    title: 'Fiche Démontage - Réducteur',
    fields: {
      ...ficheDemontageReducteur,
    },
  },
  29: {
    title: 'Fiche Démontage - Motoréducteur',
    fields: {
      ...ficheDemontageMotoreducteur,
      ...ficheDemontageReducteur,
      ...ficheDemontageExceptPompeReducteur,
      ...ficheDemontageExceptPompeReducteurMonophase,
      ...ficheDemontageTriphase,
      ...ficheDemontageMonophase,
    },
  },
  32: {
    title: 'Fiche Remontage - Moteur Monophasé',
    fields: {
      ...ficheRemontageExceptPompeReducteur,
      ...ficheRemontageMonophase,
    },
  },
  33: {
    title: 'Fiche Remontage - Moteur Alternateur',
    fields: {
      ...ficheRemontageExceptPompeReducteur,
      ...ficheRemontageExceptPompeReducteurMonophase,
      ...ficheRemontageAlternateur,
    },
  },
  35: {
    title: 'Fiche Remontage - Moteur à Courant Continu',
    fields: {
      ...ficheRemontageExceptPompeReducteur,
      ...ficheRemontageExceptPompeReducteurMonophase,
      ...ficheRemontageAlternateur,
    },
  },
  36: {
    title: 'Fiche Remontage - Moteur Triphasé',
    fields: {
      ...ficheRemontageExceptPompeReducteur,
      ...ficheRemontageExceptPompeReducteurMonophase,
    },
  },
  37: {
    title: 'Fiche Remontage - Motopompe',
    fields: {
      ...ficheRemontageMotopompe,
      ...ficheRemontagePompe,
      ...ficheRemontageExceptPompeReducteur,
      ...ficheRemontageExceptPompeReducteurMonophase,
      ...ficheRemontageMonophase,
    },
  },
  39: {
    title: 'Fiche Remontage - Motoréducteur',
    fields: {
      ...ficheRemontageMotoReducteur,
      ...ficheRemontageReducteur,
      ...ficheRemontageExceptPompeReducteur,
      ...ficheRemontageExceptPompeReducteurMonophase,
      ...ficheRemontageMonophase,
    },
  },
};
