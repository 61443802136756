/* import { regexUsername, regexPassword } from "../utils/constants"; */
import React, { useContext, useState } from 'react';

import Message from '../components/Message';
import { Context } from '../components/Store';
import { login } from '../functions/user';

export default function Login() {
  const [, setState] = useContext(Context);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const log = async () => {
    /* if (!username.match(regexUsername))
      return setError("Le nom d'utilisateur doit être valide");
    if (!password.match(regexPassword)) return setError("Le format du mot de passe n'est pas valide"); */
    setIsLoading(true);
    document.getElementById('submitBtn').disabled = true;
    const myLogin = await login({ username, password });
    setIsLoading(false);
    document.getElementById('submitBtn').disabled = false;
    if (myLogin.error) {
      setPassword('');
      setError(myLogin.error);
    } else if (myLogin.user.status > 1) {
      localStorage.setItem('user', JSON.stringify(myLogin.user));
      localStorage.setItem('token', myLogin['auth-token']);
      setState({ ...myLogin, token: myLogin['auth-token'] });
    } else {
      setUsername('');
      setPassword('');
      return setError('Connectez-vous avec un compte administrateur.');
    }
  };

  document.title = 'VIGUIER - Login';
  return (
    <div>
      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-2 px-4 sm:px-6 lg:px-8">
        <div className="max-w-lg w-full space-y-8">
          <div>
            <img src="/Logo viguier.png" alt="Viguier" />
            <h2 className="mt-6 text-center text-2xl md:text-3xl font-extrabold text-gray-900">
              Connectez-vous à votre compte
            </h2>
          </div>
          <form
            className="mt-8 space-y-6"
            onSubmit={(e) => {
              e.preventDefault();
              log();
            }}
          >
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="username" className="sr-only">
                  Nom d'utilisateur
                </label>
                <input
                  id="username"
                  name="username"
                  type="text"
                  autoComplete="username"
                  required
                  /* minLength="5" */
                  title="Le nom d'utilisateur doit être valide"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-stbSecond placeholder-gray-500 text-gray-900 rounded-t-md ring-1 ring-stbSecond transition duration-300 focus:outline-none focus:ring-2 focus:ring-stbMain focus:z-10 sm:text-sm"
                  placeholder="Nom d'utilisateur"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Mot de passe
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  /* pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$" */
                  title="Le mot de passe doit être valide"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-stbSecond placeholder-gray-500 text-gray-900 rounded-b-md ring-1 ring-stbSecond transition duration-300 focus:outline-none focus:ring-2 focus:ring-stbMain focus:z-10 sm:text-sm"
                  placeholder="Mot de passe"
                />
              </div>
            </div>
            {error ? <Message message={error} setError={setError} /> : null}
            <div>
              <button
                type="submit"
                id="submitBtn"
                className="transition duration-300 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-viguierBleu hover:bg-viguierBleuFoncé focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-stbMain"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <svg
                    width="30"
                    height="60"
                    viewBox="0 0 60 60"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M42.5 22.5V17.5C42.5 10.5 37 5 30 5C23 5 17.5 10.5 17.5 17.5V22.5C13.25 22.5 10 25.75 10 30V47.5C10 51.75 13.25 55 17.5 55H42.5C46.75 55 50 51.75 50 47.5V30C50 25.75 46.75 22.5 42.5 22.5ZM22.5 17.5C22.5 13.25 25.75 10 30 10C34.25 10 37.5 13.25 37.5 17.5V22.5H22.5V17.5ZM32.75 38.75L32.5 39V42.5C32.5 44 31.5 45 30 45C28.5 45 27.5 44 27.5 42.5V39C26 37.5 25.75 35.25 27.25 33.75C28.75 32.25 31 32 32.5 33.5C34 34.75 34.25 37.25 32.75 38.75Z"
                      fill="#EEEC0E"
                    />
                  </svg>
                </span>
                <span className="text-base text-white-300 group-hover:text-viguierJaune">
                  {isLoading ? 'Chargement...' : 'Connexion'}
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
