import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { SubmitButton } from '../components/Buttons';
import { TextFormField } from '../components/FormFields';
import Message from '../components/Message';
import { Context } from '../components/Store';
import { createUser, editUser, getUser } from '../functions/user';

export default function UserForm(props) {
  const [state] = useContext(Context);
  const history = useHistory();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState(1);
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [mode, setMode] = useState('create');
  const [error, setError] = useState('');
  const [info, setInfo] = useState('');
  const [warning, setWarning] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fetchUser = useCallback(async () => {
    setInfo('');
    setError('');
    setWarning('');
    setIsLoading(true);
    const fetchedUser = await getUser(props.match.params.id, state.token);
    setIsLoading(false);
    if (fetchedUser.error) return setError(fetchedUser.error);
    setUsername(fetchedUser.data?.username);
    setStatus(fetchedUser.data?.status);
    setNom(fetchedUser.data?.nom);
    setPrenom(fetchedUser.data?.prenom);
    document.title =
      `${fetchedUser.data?.prenom} ${fetchedUser.data?.nom}` ||
      "Mise à jour d'un utilisateur";
    document.getElementById('big-title').innerText =
      `${fetchedUser.data?.prenom} ${fetchedUser.data?.nom}` ||
      "Mise à jour d'un utilisateur";
  }, [state.token, props.match.params.id]);

  useEffect(() => {
    if (props.match.params.id) {
      setMode('update');
      fetchUser();
    } else document.title = 'Nouvel utilisateur';
  }, [fetchUser, props.match.params.id]);

  const submitForm = async () => {
    setError('');
    setWarning('');
    setInfo('');
    if (username.length === 0)
      return setWarning("Le nom d'utilisateur doit être renseigné.");
    if (nom.length === 0) return setWarning('Le nom doit être renseigné.');
    if (prenom.length === 0)
      return setWarning('Le prénom doit être renseigné.');
    if (
      (mode === 'update' && password.length > 0 && password.length < 5) ||
      (mode === 'create' && password.length < 5)
    )
      return setWarning('Le mot de passe doit faire au moins 5 caractères.');
    setIsLoading(true);
    const user =
      mode === 'create'
        ? await createUser(
            { username, password, status, nom, prenom },
            state.token
          )
        : await editUser(
            props.match.params.id,
            {
              username,
              ...(password.length > 0 && { password }),
              status,
              nom,
              prenom,
            },
            state.token
          );
    setIsLoading(false);
    if (user.error) return setError(user.error);
    if (mode === 'create')
      localStorage.setItem(
        'message',
        `L'utilisateur ${username} a bien été créé.`
      );
    else
      localStorage.setItem(
        'message',
        `L'utilisateur ${username} a bien été modifié.`
      );
    return history.push(`/gestion`);
  };

  return (
    <div className="flex flex-col items-center">
      <div className="w-full lg:w-11/12 mb-10">
        <h1
          className="mt-6 text-2xl sm:text-3xl md:text-4xl uppercase font-bold mb-8"
          id="big-title"
        >
          {mode === 'create'
            ? 'Nouvel utilisateur'
            : "Mise à jour d'un utilisateur"}
        </h1>
        {error ? <Message message={error} setError={setError} /> : null}
        {warning ? (
          <Message message={warning} type="warning" setError={setWarning} />
        ) : null}
        {info ? (
          <Message message={info} type="success" setError={setInfo} />
        ) : null}
        {isLoading ? (
          'Chargement...'
        ) : (props.match.params.id && username.length > 0) ||
          !props.match.params.id ? (
          <div className="bg-viguierBleu rounded">
            <div className="bg-viguierBleuClair text-white rounded-t">
              <div className="flex items-center justify-center py-2 font-bold">
                <div className="flex w-1/3 justify-center">
                  <h2>
                    {mode === 'create'
                      ? 'Créer un utilisateur'
                      : 'Modifier un utilisateur'}
                  </h2>
                </div>
              </div>
            </div>
            <div className="bg-viguierBleu">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  submitForm();
                }}
                className="px-4 py-3 text-white font-bold grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 border-4 border-white bg-viguierBleuClair mt-5 mb-5 ml-5 mr-5 rounded-md"
              >
                <TextFormField
                  value={prenom}
                  setter={setPrenom}
                  label="Prénom"
                  id="prenom"
                />
                <TextFormField
                  value={nom}
                  setter={setNom}
                  label="Nom"
                  id="nom"
                />
                <div className="flex flex-col text-left min-w-max">
                  <label htmlFor="status" className="mr-4">
                    Statut
                  </label>
                  <select
                    name="selectable"
                    id="status"
                    value={status}
                    className="text-black rounded py-1 mt-1 border-2 border-black "
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value={1}>Technicien</option>
                    <option value={2}>Chef d'atelier</option>
                    <option value={3}>Administrateur</option>
                  </select>
                </div>
                <TextFormField
                  value={username}
                  setter={setUsername}
                  label="Nom d'utilisateur"
                  id="username"
                />
                <TextFormField
                  value={password}
                  setter={setPassword}
                  label="Mot de passe"
                  id="password"
                  type="password"
                />
              </form>
            </div>
            <div className="bg-viguierBleuClair text-white justify-between flex rounded-b px-4 py-2">
              <SubmitButton
                action={() => history.push('/gestion')}
                label="Revenir à la liste"
              />
              <SubmitButton
                action={submitForm}
                label={
                  mode === 'create'
                    ? 'Créer un nouvel utilisateur'
                    : "Modifier l'utilisateur"
                }
              />
            </div>
          </div>
        ) : (
          <div>
            <Message
              message={`L'utilisateur ${props.match.params.id} n'a été trouvé`}
              type="warning"
              setError={setWarning}
            />
            <div className="mt-10 mb-5  text-stbMain font-semibold">
              <span
                onClick={fetchUser}
                className="cursor-pointer border-2 border-stbMain rounded-md p-1"
              >
                Réessayer
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
