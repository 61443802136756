export function SubmitButton({ action, label = 'Valider' }) {
  return (
    <div
      onClick={action}
      className="bg-viguierBleu py-1 px-2 rounded hover:bg-viguierBleuFoncé hover:text-viguierJaune cursor-pointer"
    >
      {label}
    </div>
  );
}
