import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation().pathname;
  const switchMenu = () => {
    isMenuOpen ? setIsMenuOpen(false) : setIsMenuOpen(true);
  };

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  return (
    <nav className="bg-viguierBleu">
      <div className="mx-auto px-2 lg:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-24">
          <div className="absolute inset-y-0 left-0 flex items-center lg:hidden ">
            <button
              className="inline-flex items-center justify-center p-2 rounded-md text-white-400 hover:text-white hover:bg-white-900 focus:ring-white"
              aria-expanded="false"
              onClick={switchMenu}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className={`${isMenuOpen ? 'hidden' : 'block'} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <svg
                className={`${isMenuOpen ? 'block' : 'hidden'} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="flex items-center justify-center lg:items-stretch lg:justify-start">
            <div className="hidden lg:block lg:ml-4">
              <div className="flex space-x-4">
                <Link
                  to="/"
                  className={`${
                    location === '/fiches' ||
                    location === '/' ||
                    location.includes('/fiche/') ||
                    location.includes('/fiches')
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-900 hover:text-viguierJaune'
                  } px-3 py-2 rounded-md lg:text-sm font-medium nav-link`}
                >
                  <span className="whitespace-nowrap">Fiches</span>
                </Link>
                <Link
                  to="/clients"
                  className={`${
                    location === '/clients' ||
                    location.includes('/client/') ||
                    location.includes('/clients')
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-900 hover:text-viguierJaune'
                  } px-3 py-2 rounded-md text-sm font-medium nav-link`}
                >
                  <span className="whitespace-nowrap">Clients</span>
                </Link>
                <Link
                  to="/gestion"
                  className={`${
                    location.includes('/gestion') ||
                    location.includes('/users') ||
                    location.includes('/vehicle')
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-900 hover:text-viguierJaune'
                  } px-3 py-2 rounded-md text-sm font-medium nav-link`}
                >
                  <span className="whitespace-nowrap hidden lg:block">
                    Gestion utilisateurs et véhicules
                  </span>
                  <span className="block lg:hidden">Gestion</span>
                </Link>
                <Link
                  to="/pointages"
                  className={`${
                    location === '/pointages' ||
                    location.includes('/pointages/')
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-900 hover:text-viguierJaune'
                  } px-3 py-2 rounded-md text-sm font-medium nav-link`}
                >
                  <span className="whitespace-nowrap">Pointages</span>
                </Link>
              </div>
            </div>
          </div>
          <Link to="/" className="flex-shrink-0">
            <img
              src="/Logo viguier.png"
              alt="Viguier"
              className="h-12 lg:h-14 w-auto mr-5 sm:mr-52 md:mr-80 lg:mr-15 xl:mr-30"
            />
          </Link>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 lg:static lg:inset-auto lg:ml-6 lg:pr-0 hidden lg:flex">
            <Link
              to="/logout"
              className={`${
                location === '/logout'
                  ? 'bg-gray-900 text-white'
                  : 'text-gray-300 hover:bg-gray-900 hover:text-viguierJaune'
              } px-3 py-2 truncate rounded-md text-sm font-medium nav-link`}
            >
              Déconnexion
            </Link>
          </div>
        </div>
      </div>
      <div className={`${isMenuOpen ? 'block' : 'hidden'} lg:hidden`}>
        <div className="px-2 pt-2 pb-3 space-y-1">
          <div className="h-8">
            <Link
              to="/"
              className={`${
                location === '/fiches' ||
                location === '/' ||
                location.includes('/fiche/') ||
                location.includes('/fiches')
                  ? 'bg-gray-900 text-white'
                  : 'text-gray-300 hover:bg-gray-900 hover:text-white'
              } px-3 py-2 rounded-md text-sm font-medium nav-link`}
            >
              Fiches
            </Link>
          </div>
          <div className="h-8">
            <Link
              to="/clients"
              className={`${
                location === '/clients' ||
                location.includes('/client/') ||
                location.includes('/clients')
                  ? 'bg-gray-900 text-white'
                  : 'text-gray-300 hover:bg-gray-900 hover:text-white'
              } px-3 py-2 rounded-md text-sm font-medium nav-link`}
            >
              Clients
            </Link>
          </div>
          <div className="h-8">
            <Link
              to="/gestion"
              className={`${
                location === '/gestion'
                  ? 'bg-gray-900 text-white'
                  : 'text-gray-300 hover:bg-gray-900 hover:text-white'
              } px-3 py-2 rounded-md text-sm font-medium nav-link`}
            >
              Gestion utilisateurs et véhicules
            </Link>
          </div>
          <div className="h-8">
            <Link
              to="/pointages"
              className={`${
                location === '/pointages' || location.includes('/pointages/')
                  ? 'bg-gray-900 text-white'
                  : 'text-gray-300 hover:bg-gray-900 hover:text-white'
              } px-3 py-2 rounded-md text-sm font-medium nav-link`}
            >
              Pointages
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}
