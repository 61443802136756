import NavigateBeforeOutlined from '@material-ui/icons/NavigateBeforeOutlined';
import NavigateNextOutlined from '@material-ui/icons/NavigateNextOutlined';

import { Select } from './FormFields';

export default function Pagination({
  page,
  setPage,
  nbByPage,
  setNbByPage,
  totalCount,
  fetchFunction,
}) {
  return (
    <div className="flex flex-col items-center text-lg sm:flex-row hidden lg:block">
      <div className="flex items-center flex-wrap mb-2 sm:mb-0">
        <span className="text-base">Afficher</span>
        <Select
          id="pagination"
          value={nbByPage}
          options={[5, 10, 20, 40, 80].map((v) => ({ value: v, label: v }))}
          setter={setNbByPage}
          inline
        />
      </div>
      <div className="flex items-center flex-nowrap">
        {page !== 1 ? (
          <NavigateBeforeOutlined
            className="cursor-pointer"
            onClick={() => {
              fetchFunction({ offset: nbByPage * (page - 2) });
              setPage(page - 1);
            }}
          />
        ) : (
          <NavigateBeforeOutlined className="cursor-not-allowed text-stbSecond" />
        )}
        Page {page}/{Math.ceil(totalCount / nbByPage) || 1}
        {page !== Math.ceil(totalCount / nbByPage) ? (
          <NavigateNextOutlined
            className="cursor-pointer"
            onClick={() => {
              fetchFunction({ offset: nbByPage * page });
              setPage(page + 1);
            }}
          />
        ) : (
          <NavigateNextOutlined className="cursor-not-allowed text-stbSecond" />
        )}
      </div>
    </div>
  );
}
