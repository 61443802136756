import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { SubmitButton } from '../components/Buttons';
import { TextFormField } from '../components/FormFields';
import Message from '../components/Message';
import { Context } from '../components/Store';
import { createVehicle, editVehicle, getVehicle } from '../functions/vehicle';

export default function VehicleForm(props) {
  const [state] = useContext(Context);
  const history = useHistory();
  const [vehicle, setVehicle] = useState('');
  const [kilometrage, setKilometrage] = useState(0);
  const [mode, setMode] = useState('create');
  const [error, setError] = useState('');
  const [info, setInfo] = useState('');
  const [warning, setWarning] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fetchVehicle = useCallback(async () => {
    setInfo('');
    setError('');
    setWarning('');
    setIsLoading(true);
    const FetchedVehicle = await getVehicle(props.match.params.id, state.token);
    setIsLoading(false);
    if (FetchedVehicle.error) return setError(FetchedVehicle.error);
    setVehicle(FetchedVehicle.data?.nom);
    setKilometrage(FetchedVehicle.data?.kilometrage);
    document.title = FetchedVehicle.data?.nom || "Mise à jour d'un véhicule";
    document.getElementById('big-title').innerText =
      FetchedVehicle.data?.nom || "Mise à jour d'un véhicule";
  }, [state.token, props.match.params.id]);

  useEffect(() => {
    if (props.match.params.id) {
      setMode('update');
      fetchVehicle();
    } else document.title = 'Nouveau véhicule';
  }, [fetchVehicle, props.match.params.id]);

  const submitForm = async () => {
    setError('');
    setWarning('');
    setInfo('');
    if (vehicle.length === 0)
      return setWarning('Le nom du véhicule doit être renseigné.');
    if (kilometrage < 0)
      return setWarning('Le kilométrage du véhicule doit être positif.');
    setIsLoading(true);
    const newVehicle =
      mode === 'create'
        ? await createVehicle(
            { nom: vehicle, kilometrage: kilometrage.toString() },
            state.token
          )
        : await editVehicle(
            props.match.params.id,
            { nom: vehicle, kilometrage: kilometrage.toString() },
            state.token
          );
    setIsLoading(false);
    if (newVehicle.error) return setError(newVehicle.error);
    if (mode === 'create')
      localStorage.setItem(
        'message',
        `Le véhicule ${vehicle} a bien été créé.`
      );
    else
      localStorage.setItem(
        'message',
        `Le véhicule ${vehicle} a bien été modifié.`
      );
    return history.push(`/gestion`);
  };

  return (
    <div className="flex flex-col items-center">
      <div className="w-full lg:w-11/12 mb-10">
        <h1
          className="mt-6 text-2xl sm:text-3xl md:text-4xl uppercase font-bold mb-8"
          id="big-title"
        >
          {mode === 'create' ? 'Nouveau véhicule' : "Mise à jour d'un véhicule"}
        </h1>
        {error ? <Message message={error} setError={setError} /> : null}
        {warning ? (
          <Message message={warning} type="warning" setError={setWarning} />
        ) : null}
        {info ? (
          <Message message={info} type="success" setError={setInfo} />
        ) : null}
        {isLoading ? (
          'Chargement...'
        ) : (props.match.params.id && vehicle.length > 0) ||
          !props.match.params.id ? (
          <div className="bg-viguierBleu rounded">
            <div className="bg-viguierBleuClair text-white rounded-t">
              <div className="flex items-center justify-center py-2 font-bold">
                <div className="flex w-1/3 justify-center">
                  <h2>
                    {mode === 'create'
                      ? 'Créer un véhicule'
                      : 'Modifier un véhicule'}
                  </h2>
                </div>
              </div>
            </div>
            <div className="bg-viguierBleu">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  submitForm();
                }}
                className="px-4 py-3 text-white font-bold grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-2 border-4 border-white bg-viguierBleuClair mt-5 mb-5 ml-5 mr-5 rounded-md"
              >
                <TextFormField
                  value={vehicle}
                  setter={setVehicle}
                  label="Nom du véhicule"
                  id="vehicle"
                />
                <TextFormField
                  value={kilometrage}
                  setter={setKilometrage}
                  label="Kilométrage"
                  id="kilometrage"
                  type="number"
                />
              </form>
            </div>
            <div className="bg-viguierBleuClair text-white justify-between flex rounded-b px-4 py-2">
              <SubmitButton
                action={() => history.push('/gestion')}
                label="Revenir à la liste"
              />
              <SubmitButton
                action={submitForm}
                label={
                  mode === 'create'
                    ? 'Créer un nouveau véhicule'
                    : 'Modifier le véhicule'
                }
              />
            </div>
          </div>
        ) : (
          <div>
            <Message
              message={`Le véhicule ${props.match.params.id} n'a été trouvé`}
              type="warning"
              setError={setWarning}
            />
            <div className="mt-10 mb-5 text-stbMain font-semibold">
              <span
                onClick={fetchVehicle}
                className="cursor-pointer border-2 border-stbMain rounded-md p-1"
              >
                Réessayer
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
