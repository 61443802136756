import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { SubmitButton } from '../components/Buttons';
import { TextFormField } from '../components/FormFields';
import Message from '../components/Message';
import { Context } from '../components/Store';
import { createClient, getClient, updateClient } from '../functions/client';

export default function ClientForm(props) {
  const [state] = useContext(Context);
  const history = useHistory();
  const [enterprise, setEnterprise] = useState('');
  const [telephone, setTelephone] = useState('');
  const [address, setAddress] = useState('');
  const [contact, setContact] = useState('');
  const [mode, setMode] = useState('create');
  const [error, setError] = useState('');
  const [info, setInfo] = useState('');
  const [warning, setWarning] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fetchClient = useCallback(async () => {
    setInfo('');
    setError('');
    setWarning('');
    setIsLoading(true);
    const fetchedClient = await getClient(props.match.params.id, state.token);
    setIsLoading(false);
    if (fetchedClient.error) return setError(fetchedClient.error);
    setEnterprise(fetchedClient.data?.enterprise);
    setTelephone(fetchedClient.data?.telephone || '');
    setAddress(fetchedClient.data?.address || '');
    setContact(fetchedClient.data?.contact || '');
    document.title =
      fetchedClient.data?.enterprise || "Mise à jour d'un client";
    document.getElementById('big-title').innerText =
      fetchedClient.data?.enterprise || "Mise à jour d'un client";
  }, [state.token, props.match.params.id]);

  useEffect(() => {
    if (props.match.params.id) {
      setMode('update');
      fetchClient();
    } else document.title = 'Nouveau client';
  }, [fetchClient, props.match.params.id]);

  const submitForm = async () => {
    setError('');
    setWarning('');
    setInfo('');
    if (enterprise.length === 0)
      return setWarning("Erreur : L'entreprise ne peut pas être vide.");
    setIsLoading(true);
    const client =
      mode === 'create'
        ? await createClient(
            { enterprise, telephone, address, contact },
            state.token
          )
        : await updateClient(
            props.match.params.id,
            { enterprise, telephone, address, contact },
            state.token
          );
    setIsLoading(false);
    if (client.error) return setError(client.error);
    if (mode === 'create')
      localStorage.setItem(
        'message',
        `Le client ${enterprise} a bien été créé.`
      );
    else
      localStorage.setItem(
        'message',
        `Le client ${enterprise} a bien été modifié.`
      );
    return history.push(`/clients/`);
  };

  return (
    <div className="flex flex-col items-center">
      <div className="w-full lg:w-11/12 mb-10">
        <h1
          className="mt-6 text-2xl sm:text-3xl md:text-4xl uppercase font-bold mb-8"
          id="big-title"
        >
          {mode === 'create' ? 'Nouveau client' : "Mise à jour d'un client"}
        </h1>
        {error ? <Message message={error} setError={setError} /> : null}
        {warning ? (
          <Message message={warning} type="warning" setError={setWarning} />
        ) : null}
        {info ? (
          <Message message={info} type="success" setError={setInfo} />
        ) : null}
        {isLoading ? (
          'Chargement...'
        ) : (props.match.params.id && enterprise.length > 0) ||
          !props.match.params.id ? (
          <div className="bg-viguierBleu rounded">
            <div className="bg-viguierBleuClair text-white rounded-t">
              <div className="flex items-center justify-center py-2 font-bold">
                <div className="flex w-1/3 justify-center">
                  <h2>
                    {mode === 'create'
                      ? 'Créer un client'
                      : 'Modifier un client'}
                  </h2>
                </div>
              </div>
            </div>
            <div className="bg-viguierBleu">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  submitForm();
                }}
                className="px-4 py-3 text-white font-bold grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 border-4 border-white bg-viguierBleuClair mt-5 mb-5 ml-5 mr-5 rounded-md"
              >
                <TextFormField
                  value={enterprise}
                  setter={setEnterprise}
                  label="Entreprise"
                  id="enterprise"
                />
                <TextFormField
                  value={address}
                  setter={setAddress}
                  label="Adresse"
                  id="address"
                />
                <TextFormField
                  value={contact}
                  setter={setContact}
                  label="Nom du contact"
                  id="contact"
                />
                <TextFormField
                  value={telephone}
                  setter={setTelephone}
                  label="Numéro de téléphone"
                  id="telephone"
                  type="tel"
                />
              </form>
            </div>
            <div className="bg-viguierBleuClair text-white justify-end flex rounded-b px-4 py-2">
              <SubmitButton
                action={submitForm}
                label={
                  mode === 'create'
                    ? 'Créer un nouveau client'
                    : 'Modifier le client'
                }
              />
            </div>
          </div>
        ) : (
          <div>
            <Message
              message={`Le client ${props.match.params.id} n'a été trouvé`}
              type="warning"
              setError={setWarning}
            />
            <div className="mt-10 mb-5  text-stbMain font-semibold">
              <span
                onClick={fetchClient}
                className="cursor-pointer border-2 border-stbMain rounded-md p-1"
              >
                Réessayer
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
