import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Message from '../components/Message';
import Pagination from '../components/Pagination';
import { Context } from '../components/Store';
import { deleteClient, getClients } from '../functions/client';

export default function Clients() {
  const [state] = useContext(Context);
  const [clients, setClients] = useState([]);
  const [text, setText] = useState('');
  const [error, setError] = useState('');
  const [info, setInfo] = useState('');
  const [warning, setWarning] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [clientsByPage, setClientsByPage] = useState(
    localStorage.getItem('clientsByPage') || 20
  );

  const fetchClients = async (options = {}) => {
    setInfo('');
    setError('');
    setWarning('');
    setIsLoading(true);
    const fetchedClients = await getClients(state.token, {
      limit: options.limit || clientsByPage,
      offset: options.offset || 0,
      filter: options.filter ?? text,
    });
    setIsLoading(false);
    if (fetchedClients.error) return setError(fetchedClients.error);
    setTotalCount(fetchedClients.totalCount);
    return setClients(fetchedClients.data);
  };

  useEffect(() => {
    fetchClients();
    if (localStorage.getItem('message')) {
      setInfo(localStorage.getItem('message'));
      localStorage.removeItem('message');
    }
  }, []); // eslint-disable-line

  const searchClients = () => {
    setInfo('');
    setError('');
    setWarning('');
    fetchClients();
  };

  const deleteFromList = async (id, enterprise) => {
    setError('');
    setInfo('');
    const res = window.prompt(
      `Confirmez la suppression du client ${enterprise} en renseignant le nom de l'entreprise ci-dessous. Cette action est définitive.`
    );
    if (res === enterprise) {
      setIsLoading(true);
      const deleted = await deleteClient(id, state.token);
      setIsLoading(false);
      if (deleted.error) return setError(deleted.error);
      fetchClients();
      setInfo(`Le client ${enterprise} a bien été supprimé.`);
    } else if (typeof res === 'string' || res > 0) {
      setError("Le nom de l'entreprise ne correspond pas.");
    }
  };

  function CustomPagination() {
    return (
      <Pagination
        page={page}
        setPage={setPage}
        fetchFunction={fetchClients}
        nbByPage={clientsByPage}
        setNbByPage={(nb) => {
          fetchClients({ limit: nb });
          localStorage.setItem('clientsByPage', nb);
          return setClientsByPage(nb);
        }}
        totalCount={totalCount}
      />
    );
  }

  const renderClientCards = () => {
    if (isLoading) {
      return (
        <tr>
          <td colSpan="6">
            <Message message="Chargement..." type="success" />
          </td>
        </tr>
      );
    } else if (clients.length === 0) {
      return (
        <tr>
          <td colSpan="6">
            <Message
              message={"Aucun client n'a été trouvé"}
              type="warning"
              setError={(_) => {
                setWarning('');
                setText('');
                fetchClients({ filter: '' });
              }}
            />
          </td>
        </tr>
      );
    } else {
      return clients.map((client, i) => (
        <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-4 mt-4 m-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4 sm:hidden">
          <div className="px-4 py-2 bg-viguierBleuClair text-white font-semibold uppercase">
            {client.enterprise}
          </div>
          <div className="py-4 px-6">
            <p className="text-gray-600 text-sm mb-2">
              Nom du client: {client.contact}
            </p>
            <p className="text-gray-600 text-sm mb-2">
              Numéro de téléphone: {client.telephone}
            </p>
            <p className="text-gray-600 text-sm mb-2">
              Adresse: {client.address}
            </p>
            <p className="text-gray-600 text-sm">
              Interventions liées:{' '}
              <Link to={`/fiches/client/${client._id}`}>
                <VisibilityIcon
                  className="hover:text-viguierJaune cursor-pointer ml-2"
                  alt="Voir les interventions liées"
                />
              </Link>
            </p>
          </div>
          <div className="py-2 px-4 bg-viguierBleu text-white text-center">
            <Link
              to={`/clients/${client._id}`}
              className="font-semibold py-2 px-4 rounded-full bg-viguierBleu hover:bg-viguierBleuFoncé"
            >
              Modifier
            </Link>
          </div>
          <div className="py-2 px-4 bg-viguierBleu text-white text-center">
            <DeleteIcon
              className="hover:text-viguierJaune cursor-pointer ml-5"
              onClick={() => {
                deleteFromList(client._id, client.enterprise);
              }}
            />
          </div>
        </div>
      ));
    }
  };

  document.title = 'Clients';

  return (
    <div className="flex flex-col items-center">
      <div className="w-full lg:w-11/12 mb-10">
        <h1 className="mt-6 text-2xl sm:text-3xl md:text-4xl uppercase font-bold mb-8">
          Clients
        </h1>
        {error ? <Message message={error} setError={setError} /> : null}
        {warning ? (
          <Message message={warning} type="warning" setError={setWarning} />
        ) : null}
        {info ? (
          <Message message={info} type="success" setError={setInfo} />
        ) : null}
        <div className="bg-viguierBleu rounded">
          <div className="bg-viguierBleuClair text-white rounded-t flex items-center justify-between py-2 font-bold">
            <div className="flex w-1/3 mr-2" />
            <div className="flex w-1/3 justify-center">
              <h2 className="mr-10 lg:mr-auto">Liste des clients</h2>
            </div>
            <div className="flex w-1/3 mr-2 justify-end ml-5 lg:ml-auto">
              <Link
                to="/clients/new"
                className="bg-viguierBleu mr-8 py-1 px-2 rounded hover:bg-viguierBleuFoncé hover:text-viguierJaune"
              >
                Nouveau client
              </Link>
            </div>
          </div>
          <div className="bg-viguierBleu text-white justify-between flex px-2 py-2">
            <div className="ml-2 my-1 flex items-center">
              <form
                className="mr-2"
                onSubmit={(e) => {
                  e.preventDefault();
                  searchClients();
                }}
              >
                <input
                  type="search"
                  id="searchText"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  placeholder="Rechercher un client..."
                  title="Filtrer par entreprise, numéro de téléphone, ou adresse"
                  className="w-full max-w-sm rounded pl-2 py-2 focus:outline-none text-black"
                />
              </form>
              <SearchIcon
                onClick={searchClients}
                className="cursor-pointer self-center ml-1 rounded hover:text-viguierJaune"
              />
            </div>
            <CustomPagination />
          </div>
          <div className="border-4 border-white bg-viguierBleuClair mt-5 mb-5 mr-5 rounded-md hidden sm:block">
            <table className="w-11/12 mb-4 ml-4 sm:ml-12 lg:ml-16 bg-viguierGrisClair">
              <thead className="bg-viguierBleuClair text-white h-10 ">
                <tr>
                  <th>Entreprise</th>
                  <th>Numéro de téléphone</th>
                  <th>Adresse</th>
                  <th>Contact</th>
                  <th>Interventions liées</th>
                  <th className="px-1">Options</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan="6">
                      <Message message="Chargement..." type="success" />
                    </td>
                  </tr>
                ) : clients.length === 0 ? (
                  <tr>
                    <td colSpan="6">
                      <Message
                        message={"Aucun client n'a été trouvé"}
                        type="warning"
                        setError={(_) => {
                          setWarning('');
                          setText('');
                          fetchClients({ filter: '' });
                        }}
                      />
                    </td>
                  </tr>
                ) : (
                  clients.map((client, i) => {
                    return (
                      <tr
                        key={client._id}
                        className={`hover:bg-viguierBleu hover:text-white ${
                          i % 2 === 0 && 'bg-viguierGrisFoncé'
                        }`}
                      >
                        <td>{client.enterprise}</td>
                        <td>{client.telephone}</td>
                        <td>{client.address}</td>
                        <td>{client.contact}</td>
                        <td>
                          <Link to={`/fiches/client/${client._id}`}>
                            <VisibilityIcon
                              className="hover:text-viguierJaune cursor-pointer"
                              alt="Voir les interventions liées"
                            />
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/clients/${client._id}`}
                            className="hover:text-viguierJaune"
                          >
                            <EditIcon />
                          </Link>
                          <DeleteIcon
                            className="hover:text-viguierJaune cursor-pointer ml-5"
                            onClick={() => {
                              deleteFromList(client._id, client.enterprise);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
          <div className="bg-viguierBleu text-white justify-between flex rounded-b p-2 py-2">
            <div />
            <CustomPagination />
          </div>
          {renderClientCards()}
        </div>
      </div>
    </div>
  );
}
